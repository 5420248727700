const X_AUTH_TOKEN = "tq355lY3MJyd8Uj2ySzm";

const API_BASE_URL = "https://nims3000.educore.guru/v3/";
const V0_API_BASE_URL = "https://nims3000.educore.guru/v0/";  //live
const LOGIN_API_BASE_URL = "https://nims3000.educore.guru/";

// const API_BASE_URL = "https://teamsqa3000.educore.guru/v3/";
// const V0_API_BASE_URL = "https://teamsqa3000.educore.guru/v0/"; //test
// const LOGIN_API_BASE_URL = "https://teamsqa3000.educore.guru/";

// const API_BASE_URL = "https://sqa3000.educore.guru/v3/";
// const V0_API_BASE_URL = "https://sqa3000.educore.guru/v0/"; //test
// const LOGIN_API_BASE_URL = "https://sqa3000.educore.guru/";

// const API_BASE_URL = "https://demo3000.educore.guru/v3/";
// const V0_API_BASE_URL = "https://demo3000.educore.guru/v0/"; //test
// const LOGIN_API_BASE_URL = "https://demo3000.educore.guru/";

const FILE_SERVER_URL = "https://nims4000.educore.guru/";
const DUMMY_USER_ICON =
  "https://teamsqa4000.educore.guru/uploads/ic_person_black.svg";
const SITE_URL = "https://teamsqa.educore.guru/";
const LIVEFS = "https://nims4000.educore.guru";
const LOCALURL = "http://localhost:3000/v1";
const APPCONSTANTS = {
  X_AUTH_TOKEN: X_AUTH_TOKEN,
  API_BASE_URL: API_BASE_URL,
  FILE_SERVER_URL: FILE_SERVER_URL,
  DUMMY_USER_ICON: DUMMY_USER_ICON,
  LOGIN_API_BASE_URL: LOGIN_API_BASE_URL,
  GC_CLIENT_ID:
    "808404300357-js6gr2hfetcvlshslaj7lsebg3aj0qmk.apps.googleusercontent.com",
  V0_API_BASE_URL: V0_API_BASE_URL,
  SITE_URL: SITE_URL,
  LIVEFS: LIVEFS,
  LOCALURL: LOCALURL,
};
export default APPCONSTANTS;
// const API_BASE_URL = "http://localhost:3005/v3/";
// const LOGIN_API_BASE_URL = "http://localhost:3005/";
